import React from "react"
import { AppProps } from "../AppPage"
import { Router, Redirect } from "@reach/router"
import questions from "./recyclePersonaliltyTestData"
import RecyclePersonalityQuestion from "../../organisms/RecyclePersonalityQuestion"
import DefaultLayout from "../../layouts/DefaultLayout"
import useEffectOnce from "../../../hooks/useEffectOnce"
// import styles from "./ResyclePersonalityTest.module.scss"

type RecyclePersonalityTestProps = AppProps

const INIT_STATE = {
  "1": null,
  "2": null,
  "3": null,
  "4": null,
  "5": null,
  "6": null,
  "7": null,
  "8": null,
}

/**
 * Kierrättäjäpersoona kampanjan kysymyssivut
 */
const RecyclePersonalityTest = (props: RecyclePersonalityTestProps) => {
  console.debug("Rendering RecyclePersonalityTest application", props)
  const routes = []

  useEffectOnce(() => {
    const raw = localStorage.getItem("recycler")
    const items = raw && JSON.parse(raw || "")
    if (items && !isRecyclerState(items)) {
      // error
      // TODO what to do when invalid state
      localStorage.setItem("recycler", JSON.stringify(INIT_STATE))
    }
  })

  for (const [path, data] of questions) {
    routes.push(<RecyclePersonalityQuestion key={path} path={path} data={data} questionNumber={path} />)
  }

  return (
    <DefaultLayout seoMetaTags={props.seoMetaTags} mainNav={props.mainNav}>
      <Router basepath={props.basePath}>
        {routes}
        <Redirect from="/" to="/fi/kierrattaja" default noThrow />
      </Router>
    </DefaultLayout>
  )
}

export default RecyclePersonalityTest

/**
 * Helpers and type
 */

/**
 * Recycler Campaign answer
 */
export type Answer = { id: string; points: number }

/** Number 1-8 as string. Same as path by convention */
type QuestionNumber = string

/**
 * Recycler Campaign user answer state stored in localStorage
 */
export type RecyclerState = Record<QuestionNumber, Answer | null>

export function isRecyclerState(val: any): val is RecyclerState {
  console.debug("Type guard for RecyclerState", val)
  if (!val) {
    return false
  }
  return true
}

export function isAnswer(val: any): val is Answer {
  if (!(val && typeof val === "object")) {
    return false
  }
  if ("id" in val && "points" in val) {
    return true
  }
  return false
}
