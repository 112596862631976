export type RecyclePersonaliltyQuestion = {
  question: string
  info: string
  answers: { id: string; answer: string; points: number }[]
}

const data: [path: string, data: RecyclePersonaliltyQuestion][] = [
  [
    "1",
    {
      question:
        "Eteisen pöydällä lojuu 30 Me Naiset -lehteä. Kimpaannut yhtenä aamuna ja heität ne isoon muovikassiin. Päätät viedä ne:",
      info: "Todellista kierrättämistä on tarjota lehdelle useampi lukukerta kuin yksi. Missään tapauksessa lehdet eivät kuulu sekajätteeseen.",
      answers: [
        {
          id: "roskis",
          answer: "Lähimpään roskikseen.",
          points: 0,
        },
        {
          id: "kampaamo",
          answer: "Asuntosi kivijalassa olevaan kampaamoon.",
          points: 3,
        },
        {
          id: "paperi",
          answer: "Paperinkeräysastiaan pusseineen päivineen.",
          points: 0,
        },
        {
          id: "pussi",
          answer: "Paperinkeräysastiaan, mutta pussi muovin keräykseen.",
          points: 2,
        },
        {
          id: "piilo",
          answer: "Piiloon sängyn alle.",
          points: 1,
        },
      ],
    },
  ],
  [
    "2",
    {
      question:
        "Vietit viime viikonloppuna tupaantuliaisia ja muistona ilonpidosta keittiön pöydällä komeilee useita pahvisia sipsiputkiloita, joissa on metallinen pohja. Heität ne paperikassiin ja suuntaat roskakatokselle. Mihin astiaan tiputat roskat:",
      info: "Putkilomaiset sipsipurkit saa laittaa kartonkiastiaan, vaikka niiden pohja on metallia. Muovikansi tulisi kuitenkin laittaa muovinkeräysastiaan.",
      answers: [
        {
          id: "kartonki",
          answer: "Kartonkikeräykseen koko satsi, mutta muoviset kannet muovinkeräykseen ja pussi paperinkeräykseen.",
          points: 3,
        },
        {
          id: "seka",
          answer:
            "Yrität repiä metallisia pohjia irti putkiloista, mutta homma käy työlääksi ja hermo alkaa mennä. Päätät, että paras ratkaisu on heittää koko satsi sekajätteeseen.",
          points: 1,
        },
        {
          id: "katos",
          answer:
            "Jätät pussin sellaisenaan roskakatoksen lattialle ja tuumaat, että annat jonkun toisen ratkaista tämän dilemman.",
          points: 0,
        },
        {
          id: "kellari",
          answer: "Viet putkilot kellariin ja päätät askarrella niistä ensi jouluna koristeita.",
          points: 2,
        },
      ],
    },
  ],
  [
    "3",
    {
      question:
        "Vanha sohvasi on aivan rispaantunut ja kulahtanut eikä se sovi muutenkaan uuteen sisutukseesi. Päätät vaihtaa sohvan ja toimit seuraavasti:",
      info: "Päällystämällä sohvan uudella kankaalla tai tilaamalla sille noudon kierrätyskeskukseen tarjoat sohvalle ns. uuden elämän. Näin toimii todellinen kierrättäjä. Sohvaa ei saa viedä roskakatoksille.",
      answers: [
        {
          id: "katos",
          answer:
            "Pyydät serkkusi käymään siinä puolenyön aikoihin, jotta voitte kiikuttaa vanhan sohvan uuden tieltä roskakatoksille incognito.",
          points: 0,
        },
        {
          id: "nouto",
          answer: "Soitat kierrätyskeskukseen ja tilaat sohvalle noudon kierrätykseen.",
          points: 3,
        },
        {
          id: "verhoilu",
          answer: "Päätät alkaa luovaksi ja ommella itse sohvalle uuden päällisen tai tilaat sille verhoilun.",
          points: 3,
        },
        {
          id: "kaatopaikka",
          answer: "Viet vanhan sohvan kaatopaikalle ja maksat jätemaksun.",
          points: 2,
        },
      ],
    },
  ],
  [
    "4",
    {
      question: "Mitä seuraavista muovilaaduista ei saa laittaa muovinkeräykseen?",
      info: "Muista vain numero 3. Muovipakkausten kierrätykseen kelpaavat kaikki muut muovit paitsi PVC, joka merkitään numerolla 3, 03 tai sanalla PVC. Jos pakkauksessa on PVC-merkki, älä laita sitä keräykseen. Mikäli muovipakkauksessa ei ole mitään merkkiä, voi sen silti laittaa keräykseen. PVC-muovia käytetään pakkauksissa hyvin vähän.  Sitä ei käytetä elintarvikepakkauksissa, mutta maahantuotujen työkalujen ja lelujen pakkauksissa sitä voi joskus nähdä.",
      answers: [
        {
          id: "pwh",
          answer: "PWH tai numero 5.",
          points: 0,
        },
        {
          id: "pvc",
          answer: "PVC tai numero 3.",
          points: 3,
        },
        {
          id: "cvp",
          answer: "CVP tai numero 2.",
          points: 0,
        },
        {
          id: "pwp",
          answer: "PWP tai numero 1.",
          points: 0,
        },
      ],
    },
  ],
  [
    "5",
    {
      question:
        "Sirpaleet tuovat onnea sanotaan. Rikki menneiden lasien saldo Ylioppilasjuhlien jäljiltä on sen verran merkittävä, että loppuvuosi menee väkisinkin putkeen. Jonnekin lasinpalaset tulisi kuitenkin kiikuttaa. Viet ne:",
      info: "Rikkoutunut juomalasi kuuluu sekajätteeseen. Juomalaseja, kahvipannua, uunivuokaa, posliinia, keramiikkaa ja ikkuna- tai peililasia ei saa laittaa keräykseen, koska lasipakkausjätteet kierrätetään uusiksi lasipakkauksiksi. Kierrätys teollisessa prosessissa asettaa kerätylle materiaalille laatuvaatimuksia, minkä vuoksi vain lasipullot ja -purkit soveltuvat kierrätettäviksi. Muut lasimateriaalit ovat laatuominaisuuksiltaan sellaisia, ettei niitä pystytä hyödyntämään kierrätyksessä. Niillä on esimerkiksi erilaisia sulamispisteitä tai muita ominaisuuksia, jotka estävät kierrättämisen tai vaikeuttavat sitä.",
      answers: [
        {
          id: "lasi",
          answer: "Lasinkeräykseen tietysti!",
          points: 0,
        },
        {
          id: "seka",
          answer: "Sekajätteeseen.",
          points: 3,
        },
        {
          id: "purkki",
          answer: "Kaadat ne purkkiin ja tunget ne kodin roskiskaapin perimmäiseen nurkkaan.",
          points: 1,
        },
        {
          id: "bussi",
          answer: "Bussikatoksen pikkuroskikseen työmatkalla.",
          points: 2,
        },
      ],
    },
  ],
  [
    "6",
    {
      question:
        "Huomaat, että kylpyhuoneen kaappiin on kertynyt valtava määrä vanhaksi menneitä lääkkeitä. Jotain pitäisi tällekin asialle tehdä. Päätät tarttua toimeen siltä istumalta:",
      info: "Käyttämättömiä lääkkeitä tai lääkejäämiä sisältävien pakkausten osalta on noudatettava kuntasi jätehuoltoneuvonnan antamia ohjeita. Yleensä paikallinen apteekki ottaa lääkkeitä vastaan. Käyttämättömiä lääkkeitä ei missään tapauksessa saa tyhjentää viemäriin.",
      answers: [
        {
          id: "vessa",
          answer: "Kaadat lääkkeet purkeista vessanpönttöön ja huuhtelet ongelman alas.",
          points: 0,
        },
        {
          id: "seka",
          answer: "Heität lääkkeet paketteineen päivineen sekajätteeseen.",
          points: 0,
        },
        {
          id: "apteekki",
          answer: "Pakkaat lääkkeet pieneen paperipussiin ja viet lähimpään apteekkiin hävitettäväksi.",
          points: 3,
        },
        {
          id: "huomenna",
          answer: " Ääh..Et keksi heti mihin lääkkeet pitäisi viedä ja päätät ratkaista asian huomenna.",
          points: 2,
        },
      ],
    },
  ],
  [
    "7",
    {
      question:
        "Ah mikä nautinto onkaan startata aamu täysmaitoon tehdyllä Cafe Lattella! Ainut miinus asiassa on nurkkiin kerääntyvät kahvikapselit. Mihin roskikseen ne tulisi viedä?",
      info: "Metalliset kahvikapselit saa laittaa metallinkeräykseen sellaisenaan. Myös kahvikapseleita myyvät tahot ottavat vastaan käytettyjä kapseleita. Kapselikonetta huomattavasti ekologisempi vaihtoehto on tavallinen kahvinkeitin.",
      answers: [
        {
          id: "pihdit",
          answer:
            "Revit kapselit auki pihdeillä hampaat irvessä ja tyhjennät purut biojätteeseen ja metallin laitat metallin keräykseen.",
          points: 2,
        },
        {
          id: "seka",
          answer: "Sekajätteeseen vaan.",
          points: 0,
        },
        {
          id: "metalli",
          answer: "Koko helahoito metallin keräykseen.",
          points: 2,
        },
        {
          id: "suodatin",
          answer: "Ehkä makunystyrät tottuisivat suodatinkahviinkin kauramaidon kera?",
          points: 3,
        },
      ],
    },
  ],
  [
    "8",
    {
      question:
        "Joulu on taas, joulu on taas ja uunissa kypsyy herkkukinkku! Mikä mehevä nautinto, mutta mihin ylijäänyt paistinrasva tulisi kipata?",
      info: "Jos Joulukinkun paistinrasvan haluaa laittaa jäteastiaan, se tulee pakata hyvin, jotta se ei sotke astiaa. Nestemäisen rasvan voi laittaa purkkiin ja tiiviisti suljettuna sekajätteeseen. Jähmettyneen rasvan voi laittaa myös biojätteeseen.",
      answers: [
        {
          id: "seka",
          answer: "Sekajätteeseen jähmettyneenä ilman astiaa.",
          points: 1,
        },
        {
          id: "bio",
          answer: "Biojätteeseen jähmettyneenä ilman astiaa.",
          points: 3,
        },
        {
          id: "bioneste",
          answer: "Biojätteeseen nestemäisenä.",
          points: 1,
        },
        {
          id: "viemäri",
          answer: "Viemäriin.",
          points: 0,
        },
      ],
    },
  ],
]

const recyclePersonaliltyQuestions: Map<string, RecyclePersonaliltyQuestion> = new Map(data)

export default recyclePersonaliltyQuestions
