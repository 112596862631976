import React, { InputHTMLAttributes, LabelHTMLAttributes, ReactNode } from "react"

type CheckboxProps = Omit<React.ComponentPropsWithoutRef<"input">, "type" | "name" | "onChange"> & {
  name: string
  label?: string
  labelProps?: Omit<React.ComponentPropsWithoutRef<"label">, "htmlFor">
  children?: React.ReactNode
  onChange?: (newChecked: boolean) => void
}

const Checkbox = (props: CheckboxProps) => {
  const { name, label, labelProps, children, onChange, ...inputProps } = props
  const id = `${props.name}-${props.value}`
  return (
    <div>
      <input {...inputProps} id={id} type="checkbox" name={name} onChange={e => onChange?.(e.target.checked)} />
      <label {...props.labelProps} htmlFor={id}>
        {children || label}
      </label>
    </div>
  )
}

export default Checkbox
