import { Link } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import { ApartmentData } from "../../../hooks/useApartmentSearch"
import ApartmentSearchResultCard from "../../molecules/cards/ApartmentSearchResultCard"
import * as styles from "./ApartmentSearchResultList.module.scss"

type ApartmentSearchResultListProps = {
  sort: SearchResultSortValue
  apartments: ApartmentData[]
  // totalCount: number
}

const ApartmentSearchResultList = (props: ApartmentSearchResultListProps) => {
  // console.debug("Rendering ApartmentSearchResultList", props)
  const { i18n } = useTranslation()
  const basePath = i18n.language === "en" ? "/en/housing" : "/fi/kohteet"

  return (
    <ul className={styles.list}>
      {props.apartments &&
        props.apartments.map((apartment, i) => {
          return (
            <li key={i} className={styles.item}>
              <Link to={`${basePath}/${apartment.property.slug}`}>
                <ApartmentSearchResultCard {...apartment} />
              </Link>
            </li>
          )
        })}
    </ul>
  )
}

export default ApartmentSearchResultList
