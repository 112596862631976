import React from "react"
import { Router } from "@reach/router"
import ApartmentSearchHome from "./sub-pages/ApSHomePage"
import ApartmentSearchResult from "./sub-pages/ApSResultPage"
import { AppProps } from "../../AppPage"

export type ApartmentSearchPageProps = AppProps

const ApartmentSearchPage = (props: ApartmentSearchPageProps) => {
  // console.debug("Rendering ApartmentSearch application page", props)
  const { basePath, ...rest } = props
  return (
    <Router basepath={basePath} className="router">
      <ApartmentSearchHome {...rest} path="/" />
      <ApartmentSearchResult {...rest} path="/tulos" />
    </Router>
  )
}

export default ApartmentSearchPage
