import { graphql, PageProps } from "gatsby"
import React from "react"
import DefaultLayout from "../components/layouts/DefaultLayout"
import ContactPage from "../components/pages/ContactPage"
import FrontPage from "../components/pages/FrontPage"
// import IndexPage from "../components/pages/IndexPage"
import AppPage from "../components/pages/AppPage"
import ArticlePage from "../components/pages/ArticlePage"
import CampaignPage from "../components/pages/CampaignPage"
import CampaignResultPage from "../components/pages/CampaignResultPage"

export type DatoRouteQueryProps = {
  id: string
}

const DatoRoute = (props: PageProps<Queries.DatoRoutePageQuery>) => {
  // console.debug("Rendering Gatsby Template DatoRoute", props)

  const { data } = props

  if (!data?.currentRoute) {
    console.error("No data for gatsby-template datoRoute")
    return null
  }

  const { page, seoMetaTags } = data.currentRoute

  if (!page) {
    // this happens for example when route is localised to a locale that referenced page is not
    console.error("No page in route at gatsby-template datoRoute", data)
    return null
  }

  if (!page.__typename) {
    console.error("gatsby-template datoRoute cannot define content type")
    return null
  }

  const { mainNavData } = data

  // console.debug("gatsby-template datoRoute rendering type", type)
  switch (page.__typename) {
    case "DatoCmsFrontpage":
      return (
        <DefaultLayout mainNav={mainNavData} seoMetaTags={seoMetaTags}>
          <FrontPage {...page} />
        </DefaultLayout>
      )
    case "DatoCmsContactpage":
      return (
        <DefaultLayout mainNav={mainNavData} seoMetaTags={seoMetaTags}>
          <ContactPage {...page} />
        </DefaultLayout>
      )
    case "DatoCmsArticlepage":
      return (
        <DefaultLayout mainNav={mainNavData} seoMetaTags={seoMetaTags}>
          <ArticlePage {...page} />
        </DefaultLayout>
      )
    case "DatoCmsAppPage":
      return <AppPage {...page} basePath={props.uri} mainNav={mainNavData} seoMetaTags={seoMetaTags} />
    case "DatoCmsCampaignPage":
      return (
        <DefaultLayout mainNav={mainNavData} seoMetaTags={seoMetaTags}>
          <CampaignPage {...page} basePath={props.uri} />
        </DefaultLayout>
      )
    case "DatoCmsCampaignResultPage":
      return (
        <DefaultLayout mainNav={mainNavData} seoMetaTags={seoMetaTags}>
          <CampaignResultPage {...page} />
        </DefaultLayout>
      )
    default:
      console.warn(`Unknown page type "${page.__typename as string}"`)
      return null
  }
}

export default DatoRoute

export const query = graphql`
  query DatoRoutePage($routeId: String!, $locale: String!) {
    currentRoute: datoCmsRoute(id: { eq: $routeId }) {
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      page {
        ... on Node {
          __typename
        }
        ...DatoFrontPage
        ...DatoArticlePage
        ...DatoContactPage
        ...DatoAppPage
        ...DatoCampaignPage
        ...DatoCampaignResultPage
        #   ...DatoIndexpage
      }
    }
    mainNavData: datoCmsRoute(slug: { eq: $locale }) {
      ...DatoMainNavTree
    }
  }
`
