import { graphql } from "gatsby"
import React from "react"
import RecyclePersonalityForm from "../../organisms/forms/RecyclePersonalityForm"
// import styles from "./ContentForm.module.scss"

type ContentFormProps = Queries.ContentFormFragment

const ContentForm = (props: ContentFormProps) => {
  switch (props.form) {
    case "Kierrättäjäpersoona":
      return <RecyclePersonalityForm />
    default:
      return null
  }
}

export default ContentForm

export const query = graphql`
  fragment ContentForm on DatoCmsForm {
    form
  }
`
