import { useReducer, useRef, useState } from "react"
import useUpdateEffect from "./useUpdateEffect"

const BASE_URL = "https://europe-west3-porin-yh-asunnot.cloudfunctions.net/api/v1"

type ApiError = {
  message: string
  code?: number
}

type ApiSuccessResponse<T = unknown> = { fetchState: "done"; data: T; error: undefined }
type ApiErrorResponse = { fetchState: "done"; data: undefined; error: ApiError }

export type ApiResponse<T> = ApiSuccessResponse<T> | ApiErrorResponse

function isApiState(val: unknown): val is State<unknown> {
  return typeof val === "object" && val !== null && "fetchState" in val
}

export function isApiStateError(val: unknown): val is ApiErrorResponse {
  return isApiState(val) && "error" in val && val.error !== undefined
}

export function isApiStateSuccess(val: unknown): val is ApiSuccessResponse {
  return isApiState(val) && "data" in val && val.data !== undefined
}

type FetchState = "init" | "loading" | "done"
type State<T> = { data?: T; error?: ApiError; fetchState: FetchState }
type Action<T> = { type: "loading" } | { type: "fetched"; payload: T } | { type: "error"; payload: ApiError }

function useApi<T = unknown>(endpoint: string): [(postData: unknown) => void, State<T>] {
  const [postBody, setPostBody] = useState<string | null>(null)

  // Used to prevent state update if the component is unmounted
  const cancelRequest = useRef<boolean>(false)
  const initialState: State<T> = {
    fetchState: "init",
    error: undefined,
    data: undefined,
  }

  // Keep state logic separated

  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case "loading":
        return { ...initialState, fetchState: "loading" }
      case "fetched":
        return { ...initialState, fetchState: "done", data: action.payload }
      case "error":
        return { ...initialState, fetchState: "done", error: action.payload }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(fetchReducer, initialState)

  useUpdateEffect(() => {
    // Do nothing if the API POST payload is not given
    if (!postBody) return

    cancelRequest.current = false

    const fetchData = async () => {
      dispatch({ type: "loading" })

      try {
        const response = await fetch(`${BASE_URL}/${endpoint}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: postBody,
        })

        const data = await response.json()

        if (cancelRequest.current) return

        // TODO vastauksen HTTP koodien käsittely ja eri virhetilat.
        // Nyt tyydytään vaan 2xx sarjan olevan OK ja muiden virheitä
        if (response.ok !== true) {
          dispatch({ type: "error", payload: { message: data.error || "unknown error", code: response.status } })
        } else {
          dispatch({ type: "fetched", payload: data as T })
        }
      } catch (error) {
        if (cancelRequest.current) return

        dispatch({ type: "error", payload: { message: "Request or response parsing failed" } })
      }
    }

    void fetchData()
  }, [postBody])

  function makeRequest(data: unknown) {
    const body = JSON.stringify(data)
    setPostBody(body)
  }

  return [makeRequest, state]
}

export default useApi
