import React from "react"
import * as styles from "./TextInput.module.scss"

type TextInputProps = {
  name: string
  label: string
  id?: string
  labelProps?: React.ComponentPropsWithoutRef<"label">
} & React.ComponentPropsWithoutRef<"input">

const TextInput = (props: TextInputProps) => {
  const { name, label, id, labelProps, ...inputProps } = props

  return (
    <div className={styles.container}>
      <label htmlFor={id || name} {...props.labelProps}>
        {props.label}
        {inputProps.required ? " *" : ""}
      </label>
      <input id={id || name} name={name} type={props.type || "text"} {...inputProps} />
    </div>
  )
}

export default TextInput
