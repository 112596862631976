import React from "react"
import { Router, Redirect } from "@reach/router"
import { AppProps } from "../AppPage"
import ApartmentImagesPage from "./sub-pages/ApartmentImagesPage"
import { useTranslation } from "react-i18next"

type ApartmentImagesHomePageProps = AppProps

const ApartmentImagesHomePage = (props: ApartmentImagesHomePageProps) => {
  // console.debug("Rendering ApartmentImagesHomePage", props)
  const { basePath, ...rest } = props
  const { i18n } = useTranslation()
  // jos vaihdat polkuja, vaihda ne myös firebase.json tiedostoon vastaaviksi
  const notFoundPath = i18n.language === "en" ? "/en/apartment-search" : "/fi/asuntohaku"

  return (
    <Router basepath={basePath}>
      <ApartmentImagesPage {...rest} path="/:id" />
      <Redirect from="/" to={notFoundPath} default noThrow />
    </Router>
  )
}

export default ApartmentImagesHomePage
