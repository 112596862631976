import { graphql } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import type { MarkdownRemarkHtmlAst } from "gatsby-source-datocms"
import React from "react"
import Markdown from "../markdown/Markdown"
import * as styles from "./ContentImageTextSection.module.scss"

type ContentImageTextSectionProps = Queries.ContentImageTextSectionFragment

const ContentImageTextSection = (props: ContentImageTextSectionProps) => {
  // console.debug("Rendering ContentImageTextSection", props)
  const htmlAst = props.textNode?.childMarkdownRemark?.htmlAst
  return (
    <section className={`${styles.container}`}>
      <div className={styles.wrap}>
        {props.image?.gatsbyImageData && (
          <GatsbyImage
            image={props.image.gatsbyImageData as unknown as IGatsbyImageData}
            alt={props.image.alt || props.image.title || ""}
          />
        )}
        <div className={styles.text}>
          {htmlAst && <Markdown headingShift={1} htmlAst={htmlAst as unknown as MarkdownRemarkHtmlAst} />}
        </div>
      </div>
    </section>
  )
}

export default ContentImageTextSection

export const query = graphql`
  fragment ContentImageTextSection on DatoCmsImageText {
    image {
      alt
      title
      gatsbyImageData(layout: CONSTRAINED, height: 500)
    }
    textNode {
      ...MarkdownRemark
    }
  }
`
