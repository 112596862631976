import { Link } from "gatsby"
import React, { ReactNode } from "react"
import Arrow from "../../images/icons/arrow.inline.svg"
import * as styles from "./CtaLinkInternalLeft.module.scss"

type CtaLinkInternalLeftProps = {
  to: string
  children: ReactNode
}
//className={`pori-btn primary  ${styles.link_left}`}
const CtaLinkInternalLeft = (props: CtaLinkInternalLeftProps) => {
  return (
    <Link to={props.to} className={styles.link_left}>
      {props.children} <Arrow className={styles.arrow_left} />
    </Link>
  )
}

export default CtaLinkInternalLeft
