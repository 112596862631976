import { useRef } from "react"
import { gql } from "graphql-request"
import useHasura, { HasuraState } from "./useHasura"

/**
 * Yksittäisen asunnon kuvat ja muutamat asuntotiedot Tampuuri välityspalvelimelta noutava hook.
 * Käytetään asuntokuvasivun datalähteenä
 */

// graphql-request paketin gql on "Convenience passthrough template tag" joka ei tee mitään, mutta IDE osaa lisätä värityksen ja apurit sen avulla
const query = gql`
  query ApartmentPage($id: Int!) {
    apartment(where: { id: { _eq: $id }, is_faux_hidden: { _eq: false } }) {
      rent
      size
      # is_vacant
      name: parameters(path: "nimi")
      property {
        id
        slug
        hood
        street_address
        zip_code
      }
      media(order_by: { position: asc }) {
        id
        url
        position
        category
      }
    }
  }
`
export type ApartmentImagesData = {
  rent: number
  size: number
  // is_vacant: boolean
  name: string
  property: {
    id: number
    slug: string
    hood: string
    street_address: string
    zip_code: string
  }
  media: Array<{
    id: number
    url: string
    position: number
    category: HasuraMediaCategory
  }>
}

/**
 * Rajapinnasta palautettavan datan muoto
 */
export type ApartmentImagesResult = {
  apartment?: [ApartmentImagesData]
}

// ApartmentPage queryn muuttujat
type QueryVariables = {
  id?: number
}

export default function useApartmentImages(
  apartmentId: number | undefined
): readonly [HasuraState<ApartmentImagesResult>, (n: number) => void] {
  // parametrin vaihtamalla voi tehdä uuden rajapintakutsun
  // const [apId, setApId] = useState<number | undefined>(apartmentId) // kun undefined, hook ei tee mitään
  const apartmentIdRef = useRef(apartmentId) // kun undefined, hook ei tee mitään

  // query ei tod.näk. vaihdu alustuksen jälkeen joten se voidaan toistaiseksi välittää vain alustuksussa
  const [state, makeQuery] = useHasura<QueryVariables, ApartmentImagesResult>(
    query,
    { id: apartmentIdRef.current },
    {
      apartment: undefined,
    }
  )

  const setApartmentId = (apartmentId: number) => {
    if (apartmentId !== apartmentIdRef.current) {
      apartmentIdRef.current = apartmentId
      makeQuery({ id: apartmentIdRef.current })
    }
  }

  return [state, setApartmentId]
}
