import React from "react"
import { useTranslation } from "react-i18next"
import { ApartmentData } from "../../../hooks/useApartmentSearch"
import placeholderImage from "../../../images/property-placeholder.png"
import * as styles from "./ApartmentSearchResultCard.module.scss"

export type ApartmentSearchResultCardProps = ApartmentData

const ApartmentSearchResultCard = (props: ApartmentSearchResultCardProps) => {
  // console.debug("Rendering ApartmentSearchResultCard", props)
  const { t } = useTranslation("asuntosivu")

  const hoodLine = [props.property.hood.trim(), props.property.city.trim()].filter(Boolean).join(", ")
  const image = props.property.media[0]

  return (
    <article className={styles.container}>
      <header>
        <span className="address">{props.address.trim()}</span>
        <span className="hood">{hoodLine}</span>
        <span className="sr-only">{props.status}</span>
      </header>
      <div className={styles.image}>
        {image ? (
          <img
            src={`${image.url}?auto=format&crop=center&fit=crop&w=271&h=238`}
            alt={t("alt.propertyFacadeThumb", { address: props.address })}
            width={271}
            height={238}
            loading="lazy"
          />
        ) : (
          <img src={placeholderImage} alt="" width={271} height={238} />
        )}
        <div className={styles.labels}>
          {props.is_vacant && props.hide_status === false && <span>{t(props.status)}</span>}
        </div>
      </div>

      <footer className={styles.info}>
        <span className="size">{props.size} m²</span>
        <span className="rent">{props.rent} €/kk</span>
        <hr />
        <span className="type">{props.tyyppi}</span>
      </footer>
    </article>
  )
}

export default ApartmentSearchResultCard
