import { graphql } from "gatsby"
import React from "react"
// import ContentCtaButtonIconSection from "./modular-blocks/ContentCtaButtonIconSection"
import ContentCtaButtonIconSection from "./modular-blocks/ContentCtaButtonIconSection"
import ContentCtaCardSection from "./modular-blocks/ContentCtaCardSection"
import ContentForm from "./modular-blocks/ContentForm"
import ContentImage from "./modular-blocks/ContentImage"
import ContentImageText from "./modular-blocks/ContentImageTextSection"
import ContentTextSection from "./modular-blocks/ContentTextSection"
// import ContentResidentInfoSection from "./modular-blocks/ContentResidentInfoSection"
// import ContentPropertyCardSection from "./modular-blocks/ContentPropertyCardSection"

type CampaignPageContentProps = Queries.DatoCampaignPageContentFragment

const CampaignPageContent = (props: CampaignPageContentProps) => {
  // console.debug("Rendering CampaignPageContent", props)

  const { content } = props
  if (!content) {
    console.warn("Invalid props for CampaignPageContent")
    return null
  }

  return (
    <>
      {content.map(field => {
        if (!field) return null
        switch (field.__typename) {
          // case "DatoCmsCtaButtonIconSection":
          //   return <ContentCtaButtonIconSection key={field.id} {...field} />
          case "DatoCmsCtaButtonIconSection":
            return <ContentCtaButtonIconSection key={field.id} {...field} />
          case "DatoCmsCtaCardSection":
            return <ContentCtaCardSection key={field.id} {...field} />
          case "DatoCmsTextSection":
            return <ContentTextSection key={field.id} {...field} />
          case "DatoCmsImage":
            return <ContentImage key={field.id} {...field} />
          case "DatoCmsForm":
            return <ContentForm key={field.id} {...field} />
          case "DatoCmsImageText":
            return <ContentImageText key={field.id} {...field} />
          // case "DatoCmsResidentInfoSection":
          //   return <ContentResidentInfoSection key={field.id} {...field} />
          // case "DatoCmsPropertyCardSection":
          //   return <ContentPropertyCardSection key={field.id} {...field} />
        }
      })}
    </>
  )
}

export default CampaignPageContent

export const query = graphql`
  fragment DatoCampaignPageContent on DatoCmsCampaignPage {
    content {
      ... on Node {
        id
        __typename
      }
      ...ContentTextSection
      ...ContentCtaButtonIconSection
      ...ContentCtaCardSection
      ...ContentImage
      ...ContentForm
      ...ContentImageTextSection
      # ...ContentResidentInfoSection
      # ...ContentPropertyCardSection
    }
  }
`
