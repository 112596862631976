import { Reducer, useEffect, useReducer, useRef } from "react"
import { GraphQLClient } from "graphql-request"

enum HasuraActionType {
  init = "INIT",
  success = "SUCCESS",
  failure = "FAIL",
}

type HasuraAction<T> =
  | { type: HasuraActionType.init }
  | {
      type: HasuraActionType.success
      payload: T
    }
  | {
      type: HasuraActionType.failure
      error: Error
    }

export type HasuraState<T> = {
  loading: boolean
  data: T
  error: Error | null
}

function hasuraReducer<T>(state: HasuraState<T>, action: HasuraAction<T>): HasuraState<T> {
  // console.debug("useHasura hasuraReducer", state, action)
  switch (action.type) {
    case HasuraActionType.init:
      // console.debug("useHasura hasuraReducer", HasuraActionType.init, action)
      return {
        ...state,
        loading: true,
      }
    case HasuraActionType.success:
      // console.debug("useHasura hasuraReducer", HasuraActionType.success, action)
      return {
        loading: false,
        error: null,
        data: action.payload,
      }
    case HasuraActionType.failure:
      // console.debug("useHasura hasuraReducer", HasuraActionType.failure, action)
      return {
        ...state,
        loading: false,
        error: action.error,
      }
  }
}

/**
 * Yksinkertainen rajapintakutsun GraphQL queryn ja muuttujien hallinta
 */
enum ParameterActionType {
  set = "SET",
}
type ParameterAction<T> = { type: ParameterActionType.set; payload: { query: string; variables: T } }
type ParameterState<T> = {
  query: string
  variables: T
}
function parameterReducer<T>(state: ParameterState<T>, action: ParameterAction<T>): ParameterState<T> {
  switch (action.type) {
    case ParameterActionType.set:
      // console.debug("useHasura parameterReducer", ParameterActionType.set, action.payload)
      return {
        ...state,
        query: action.payload.query,
        variables: action.payload.variables,
      }
  }
}

// alusta GrapQL client rajapintakutsuja varten
const endpoint = "https://nitro-service-visma-tampuuri-hasura-pori.dev.nitro.fi/v1/graphql"
const client = new GraphQLClient(endpoint)

/**
 *
 * @param initialQuery GraphQL query
 * @param initialVariables Query variables if any
 * @param initialResult Initial result as initial/default value of query result.
 */
export default function useHasura<V, T>(
  initialQuery: string,
  initialVariables: V,
  initialResult: T
): readonly [HasuraState<T>, (variables: V, query?: string) => void] {
  const isMounted = useRef(false) // TODO käytetään estämään useEffect() ekalla rendauksella. Onko parempaa tapaa?

  // parametrien vaihtoon myös reducer jotta vaihto voidaan tehdä kerralla ja varsinaisen
  // rajapintakutsun tekevän useEffect hookin riippuvuksiin laitta vain yksi arvo
  const [paramState, paramDispatch] = useReducer<Reducer<ParameterState<V>, ParameterAction<V>>>(parameterReducer, {
    query: initialQuery,
    variables: initialVariables,
  })

  // rajapintakutsun tilaa seuraava
  const [state, dispatch] = useReducer<Reducer<HasuraState<T>, HasuraAction<T>>>(hasuraReducer, {
    loading: false,
    data: initialResult,
    error: null,
  })

  // wrap paramDispatch to function that can be exposed without exposing full paramDispatch
  const makeQuery = (variables: V, query?: string) => {
    const q = query || paramState.query // käytä alkuperäistä querya jos kutsuja ei anna uutta
    // console.debug("useHasura paramDispatch", { variables, query: q })
    paramDispatch({ type: ParameterActionType.set, payload: { variables, query: q } })
  }

  useEffect(() => {
    let cancelled = false

    const makeRequest = async (): Promise<void> => {
      dispatch({ type: HasuraActionType.init })
      let result
      try {
        result = await client.request<T, V>(paramState.query, paramState.variables)
      } catch (err) {
        if (!cancelled) {
          dispatch({ type: HasuraActionType.failure, error: err })
        }
        return
      }
      if (!cancelled) {
        dispatch({ type: HasuraActionType.success, payload: result })
      }
    }

    if (isMounted.current) {
      makeRequest()
    }

    return (): void => {
      cancelled = true
    }
  }, [paramState])

  useEffect(() => {
    isMounted.current = true
    return (): void => {
      isMounted.current = false
    }
  }, [])

  return [state, makeQuery]
}
