import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { PageContext } from "../../context/PageContext"
import AttentionBulletin from "./AttentionBulletin"
import { MarkdownRemarkFragment } from "./markdown/Markdown"

type AttentionBulletinData = {
  allDatoCmsAttentionBulletin: {
    nodes: AttentionBulletData[]
  }
}

export type AttentionBulletData = {
  id: string
  locale: string
  title?: string
  paragraphNode?: MarkdownRemarkFragment
  validFrom: string
  validThrough: string
}

// type AttentionBulletinsProps = {}

const AttentionBulletins = () => {
  // console.debug("Rendering template AttentionBulletin", props)
  const data = useStaticQuery<AttentionBulletinData>(graphql`
    query AttentionBulletinQuery {
      allDatoCmsAttentionBulletin {
        nodes {
          id
          locale
          title
          paragraphNode {
            childMarkdownRemark {
              htmlAst
            }
          }
          validFrom(locale: "fi")
          validThrough(locale: "fi") # string | null
        }
      }
    }
  `)
  const { locale } = useContext(PageContext)

  const nodes = data.allDatoCmsAttentionBulletin.nodes.filter(itm => itm.locale === locale)

  if (!nodes) {
    console.warn("Invalid props for AttentionBulletin")
    return null
  }

  return (
    <>
      {nodes.map(node => (
        <AttentionBulletin key={node.id} {...node} />
      ))}
    </>
  )
}

export default AttentionBulletins
