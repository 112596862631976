import { useRef } from "react"
import { gql } from "graphql-request"
import useHasura, { HasuraState } from "./useHasura"
import { isObjEqualShallow } from "../utils"

/**
 * Asuntohakuun liittyvät rajapintakutsut ja niiden queryt, alustus jne.
 *
 * Määrittää asuntohaun GraphQL queryn, sen muuttujat ja arvojen muuttamisen
 * hakukomponentin ja rajapinnan välillä.
 * Käyttää useHasura hookkia varsinaisten kutsujen tekoon
 */

//
// https://github.com/prisma-labs/graphql-request/issues/139
// Koska ym bugi, ei voida käyttää enumia oikein
//   $apartmentType: [Tampuuri_apartment_type_category_enum!]
//   type_category: { _in: $apartmentType }
// vaan pitää olla
//   $apartmentType: [String!]
//   apartment_type_category: {value: {_in: $apartmentType}}
// graphiql:ssä enumit toimii, selaimessa ei ja ero on graphql-request

// graphql-request paketin gql on "Convenience passthrough template tag" joka ei tee mitään, mutta IDE osaa lisätä värityksen aja apurit sen avulla
const query = gql`
  query ApartmentSearch(
    $buildingType: [String!]
    $apartmentType: [String!]
    $housingType: [String!]
    $features: [String!]
    $hood: String
    $zipCode: String
    $streetAddress: String
    $rentMax: numeric
    $sizeMin: numeric
    $sizeMax: numeric
    $free: Boolean
    $hideStatus: Boolean
    $limit: Int
    $offset: Int
  ) {
    apartment_aggregate(
      where: {
        building: { type: { _in: $buildingType }, housing_type: { _in: $housingType } }
        property: { hood: { _eq: $hood }, street_address: { _ilike: $streetAddress }, zip_code: { _eq: $zipCode } }
        apartment_type_category: { value: { _in: $apartmentType } }
        rent: { _lt: $rentMax }
        size: { _gt: $sizeMin, _lt: $sizeMax }
        is_vacant: { _eq: $free }
        hide_status: { _eq: $hideStatus }
        is_faux_hidden: { _eq: false }
      }
      order_by: { rent: asc }
    ) {
      aggregate {
        count
      }
    }
    apartment(
      where: {
        building: { type: { _in: $buildingType }, housing_type: { _in: $housingType } }
        property: { hood: { _eq: $hood }, street_address: { _ilike: $streetAddress }, zip_code: { _eq: $zipCode } }
        apartment_type_category: { value: { _in: $apartmentType } }
        rent: { _lt: $rentMax }
        size: { _gt: $sizeMin, _lt: $sizeMax }
        is_vacant: { _eq: $free }
        hide_status: { _eq: $hideStatus }
        is_faux_hidden: { _eq: false }
      }
      order_by: { rent: asc }
      limit: $limit
      offset: $offset
    ) {
      id
      rent
      size
      is_vacant
      hide_status
      address: parameters(path: "katuosoite")
      tyyppi: parameters(path: "huoneistotyyppi")
      status: parameters(path: "huoneistoStatus")
      building {
        type: parameters(path: "tyyppi")
      }
      property {
        id
        hood
        city: parameters(path: "sijaintikunta")
        slug
        media(limit: 1, order_by: { position: asc, size: desc }, where: { category: { _in: [facade, marketing] } }) {
          url
          format
          height
          width
        }
      }
    }
  }
`

/**
 * Muuntaa hakuparametrit sisäisestä tallunnusmuodosta GraphQL query variableiksi
 * @param params
 * @param limit
 * @param offset
 */
function toQueryParams(params: ApartmentSearchParameters, limit: number, offset: number) {
  // poimi arvot, jotka ovat lomakkeessa (ApartmentSearchFormValues) mutta ei GraphQL hakuparametreissä (QueryParams eli ApartmentSearchVariables + limit ja offset)
  const { address, ...directParams } = params
  // luo QueryParams objekti
  const queryParams: QueryParams = {
    ...directParams,
    limit,
    offset,
  }

  // Käsittele loput parameterit
  // address -> zipcode tai streetAddress
  const addr = address?.trim()
  if (!isNaN(addr as unknown as number)) {
    queryParams.zipCode = addr
    queryParams.streetAddress = undefined
  } else {
    queryParams.zipCode = undefined
    queryParams.streetAddress = addr ? `%${addr}%` : undefined
  }
  // jos etsitään vain vapaita, myös ne joita ei näytetä vapaana ei pitäisi tulla hakutulokseen
  if (queryParams.free === true) {
    queryParams.hideStatus = false
  }

  return queryParams
}

export type ApartmentData = {
  id: number
  rent: number
  size: number
  // is_free: boolean
  is_vacant: boolean
  hide_status: boolean
  address: string
  tyyppi: string
  status: string
  building: {
    type: string
  }
  property: {
    id: number
    hood: string
    city: string
    slug: string
    media: Array<{
      url: string
      height: number
      width: number
    }>
  }
}

/**
 * Rajapinnasta palautettavan datan muoto
 */
export type ApartmentSearchResult = {
  apartment: ApartmentData[]
  apartment_aggregate: {
    aggregate: {
      count: number
    }
  }
}

// ApartmentSearch GraphQL queryn muuttujat
type QueryParams = {
  hideStatus?: boolean
  limit: number
  offset: number
} & ApartmentSearchVariables

export default function useApartmentSearch(): readonly [
  HasuraState<ApartmentSearchResult>,
  (n: ApartmentSearchParameters | undefined) => void,
  (n: number) => void,
  (n: number) => void
] {
  // parametrit vaihtamalla voi tehdä uuden rajapintakutsun
  const params = useRef<ApartmentSearchParameters | undefined>(undefined) // kun undefined, hook ei tee mitään
  const limit = useRef(100)
  const offset = useRef(0)
  // const [offset, setOffset] = useState(0) // sivustus, koko tulossetin indeksi josta lähtien palauttaa tulokset. Eka tulos on indeksi 0
  // query ei tod.näk. vaihdu alustuksen jälkeen joten se voidaan toistaiseksi välittää vain alustuksussa
  const [state, makeQuery] = useHasura<QueryParams | undefined, ApartmentSearchResult>(
    query,
    { limit: limit.current, offset: offset.current },
    {
      apartment: [],
      apartment_aggregate: {
        aggregate: {
          count: 0, // TODO mikä on oikea oletusarvo vai voiko sellaista edes olla?
        },
      },
    }
  )

  const setLimit = (newLimit: number) => {
    if (newLimit !== limit.current) {
      limit.current = newLimit
    }
  }

  const setOffset = (newOffset: number) => {
    if (newOffset !== offset.current) {
      offset.current = newOffset
    }
  }

  const setParams = (newParams: ApartmentSearchParameters | undefined) => {
    // console.debug("useApartmentSearch setParams() state", state)
    if (newParams === undefined) {
      params.current = undefined
      return
    }

    if (params.current !== undefined && isObjEqualShallow(params.current, newParams)) {
      return
    }

    if (state.loading) {
      return
    }
    if (state.error !== null) {
      return
    }

    params.current = newParams

    const queryParams = toQueryParams(params.current, limit.current, offset.current)

    // console.debug("Making query", newParams, queryParams)
    makeQuery(queryParams)
  }

  return [state, setParams, setOffset, setLimit]
}
