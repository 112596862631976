import React, { useContext } from "react"
import { UserDispatchContext, UserState, UserStateContext } from "../context/UserContext"
import { ApartmentSearchActions } from "../context/userReducers"

/**
 * Helper to easily read from global and persistent User data
 *
 * ```
 * // Usage
 * const dispatch = useUserDispatch()
 * ```
 */
export default function useUserState(): UserState {
  const context = useContext(UserStateContext)
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserContextProvider")
  }
  return context
}

/**
 * Helper to easily dispatch changes to global and persistent User data
 *
 * ```
 * // Usage
 * const dispatch = useUserDispatch()
 * ```
 */
export function useUserDispatch(): React.Dispatch<ApartmentSearchActions> {
  const context = useContext(UserDispatchContext)
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserContextProvider")
  }
  return context
}

/**
 * Helper for those who need both read and dispatch changes to global User .
 *
 * ```
 * // Usage
 * const [state, dispatch] = useUser()
 * ```
 */
export function useUser(): readonly [UserState, React.Dispatch<ApartmentSearchActions>] {
  return [useUserState(), useUserDispatch()]
}
