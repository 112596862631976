import React from "react"
// import { SearchResultModes } from "../../../context/userReducers"
// import ApartmentSearchResultMap from "../../organisms/apartmentSearch/ApartmentSearchResultMap"
import ApartmentSearchResultList from "../../organisms/apartmentSearch/ApartmentSearchResultList"
import Spinner from "../../atoms/Spinner"
import * as styles from "./ApartmentSearchResult.module.scss"
import { useTranslation } from "react-i18next"
import { ApartmentData } from "../../../hooks/useApartmentSearch"

type ApartmentSearchResultProps = {
  loading?: boolean
  error?: boolean
  apartments: ApartmentData[]
  totalCount: number
  resultMode?: SearchResultMode
  // setResultMode: (value: SearchResultMode) => void
}

const ApartmentSearchResult = (props: ApartmentSearchResultProps) => {
  // console.debug("Rendering ApartmentSearchResult", props)
  const { apartments, totalCount /*, resultMode, setResultMode */ } = props
  const { t } = useTranslation("apartmentSearchForm")
  // const resultMode = SearchResultModes.List

  // const sortValues = [
  //   ["freeStatus", "Heti vapaat ensin"],
  //   ["rentAsc", "Edullisin vuokra ensin"],
  //   ["sizeDesc", "Suurin ensin"],
  // ]

  if (props.loading) {
    return (
      <div style={{ width: "100px", height: "100px" }}>
        <Spinner size="contained" />
      </div>
    )
  }

  // const result =
  //   resultMode === SearchResultModes.Map ? (
  //     <ApartmentSearchResultMap apartments={apartments} />
  //   ) : (
  //     <ApartmentSearchResultList apartments={apartments} sort="ageAst" />
  //   )
  const result = <ApartmentSearchResultList apartments={apartments} sort="ageAst" />

  return (
    <>
      <header className={styles.header}>
        <p>
          {t("searchFound", "Haullasi löytyi")} <strong>{totalCount}</strong> {t("result", { count: totalCount })}
        </p>
        {/* <div>
          <label htmlFor="sort" className="sr-only">
            Järjestä hakutulokset
          </label>
          {resultMode === SearchResultModes.List && (
            <select id="sort" name="sort" defaultValue="ageAst">
              {sortValues.map(([value, label], i) => (
                <option key={i} value={value}>
                  {label}
                </option>
              ))}
            </select>
          )}
        </div> */}
        {/* <button
          type="button"
          value={SearchResultModes.Map}
          disabled={resultMode === SearchResultModes.Map}
          onClick={(): void => setResultMode(SearchResultModes.Map)}>
          Näytä kartalla
        </button>
        <button
          type="button"
          value={SearchResultModes.List}
          disabled={resultMode === SearchResultModes.List}
          onClick={(): void => setResultMode(SearchResultModes.List)}>
          Näytä listana
        </button> */}
      </header>
      {result}
      <footer>
        <p>{t("showing", { count: apartments.length, total: totalCount })}</p>
        {/* {resultMode === SearchResultModes.List && (
          <button
            type="button"
            value={"next"}
            disabled={apartments.length >= totalCount} // TODO
            onClick={(): void => setResultMode(SearchResultModes.List)}>
            Nouda lisää
          </button>
        )} */}
      </footer>
    </>
  )
}

export default ApartmentSearchResult
