import { graphql } from "gatsby"
import React from "react"
import CtaLinkInternalLeft from "../atoms/CtaLinkInternalLeft"
import ShareLinks from "../molecules/ShareLinks"
import CampaignHeroImage from "../organisms/CampaignHeroImage"
// import * as styles from "./CampaignPage.module.scss"

type CampaignPageResultProps = Queries.DatoCampaignResultPageFragment

/**
 * Kampanjasivu, yleisesti.
 * Käytetään alkuun vain Kierrättäjäpersoona kampanjassa
 */
const CampaignResultPage = (props: CampaignPageResultProps) => {
  console.debug("Rendering template CampaignResultPage", props)
  const { title, resultText, image } = props
  if (!resultText || !title) {
    console.warn("Invalid props for CampaignResultPage")
    return null
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: 800,
        margin: "auto",
        textAlign: "center",
      }}>
      <CampaignHeroImage image={image} />

      <div>
        <h1>
          <span style={{ fontSize: "60%", display: "block" }}>Sinä olet</span> {props.title}
        </h1>
      </div>
      {resultText && <p className="ingress">{resultText}</p>}

      {/* <DatoCampaignpageContent content={props.content} /> */}

      {/* TODO! Alla olevat ei ole kampanjajuttuja vaan kierrättäjäkampanjajuttuja. Tee tästä geneerinen. */}
      <div style={{ margin: "1rem auto" }}>
        <ShareLinks
          url="https://www.porinyhasunnot.fi/fi/kierrattaja/"
          text=""
          title=""
          labels={{ share: "Jaa tuloksesi sosiaalisessa mediassa" }}
        />
      </div>
      <div style={{ display: "flex", gap: "1rem", margin: "1rem" }}>
        <CtaLinkInternalLeft to={"/fi/kierrattaja"}>Tee testi uudelleen</CtaLinkInternalLeft>
        {/* <CtaLinkInternal to={"/fi/kierrattaja/kierrattaja-osallistu"}>Osallistu arvontaan</CtaLinkInternal> */}
      </div>
    </div>
  )
}

export default CampaignResultPage

export const query = graphql`
  fragment DatoCampaignResultPage on DatoCmsCampaignResultPage {
    title
    image {
      ...DatoCampaignHeroImage
    }
    resultText
  }
`
