import React from "react"
import * as styles from "./AnswerButton.module.scss"

type AnswerButtonProps = {
  disabled?: boolean
  /** Has user selected this answer */
  isClicked?: boolean | null
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  children: React.ReactNode
}

/**
 * TODO:
 * - styles for disabled state
 * - styles for isClicked state
 */

/**
 * Clickable button like element for campaign quizzes etc.
 */
const AnswerButton = (props: AnswerButtonProps) => {
  return (
    <button
      className={`${styles.button} ${props.isClicked ? styles.isClicked : ""}`}
      disabled={props.disabled}
      onClick={props.onClick}>
      {props.children}
    </button>
  )
}

export default AnswerButton
