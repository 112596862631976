import { RouteComponentProps } from "@reach/router"
import { graphql, navigate } from "gatsby"
import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useAparmentSearchParams } from "../../hooks/useApartmentSearchParams"
import useUserState from "../../hooks/useUserState"
import { objectToUrlParams } from "../../utils"
import AttentionBulletins from "../dato/AttentionBulletins"
import FrontPageContent from "../dato/FrontPageContent"
import HeroImage from "../organisms/HeroImage"
import ApartmentSearchForm from "../organisms/forms/ApartmentSearchForm"
import { AppPageProps } from "./AppPage"
import * as styles from "./FrontPage.module.scss"

export type FrontPageProps = Queries.DatoFrontPageFragment &
  RouteComponentProps &
  Omit<AppPageProps, "basePath" | "application">

const FrontPage = (props: FrontPageProps) => {
  // console.debug("Rendering template FrontPage", props)
  const { content, title, ingress, image, mobileImage } = props
  if (!content || !title) {
    console.warn("Invalid props for FrontPage")
    return null
  }

  const { t } = useTranslation("frontpage")

  const isMounted = useRef(false)
  const userState = useUserState()
  const [activeParams] = useAparmentSearchParams(userState.apartmentSearch.params, props.location?.search)

  useEffect(() => {
    if (!isMounted.current) {
      return
    }

    const { /*resultMode, */ changeCount } = userState.apartmentSearch
    // tee lomakkeen arvoista URL-parametri lista navigaatiota varten. Parameterit välitetään myös URLissä jotta siitä tulee
    // jaettava eikä hakukriteerit ole vain piilossa
    const queryParams = objectToUrlParams(activeParams as Record<string, string>)

    if (changeCount === 0) {
      return
    }

    // siirrä käyttäjä tulossivulle
    navigate(`asuntohaku/tulos?${queryParams}`)
  }, [activeParams, userState.apartmentSearch])

  useEffect(() => {
    isMounted.current = true
  }, [])
  return (
    <>
      <HeroImage image={image} mobileImage={mobileImage} />
      <section className={styles.searchSection}>
        <h2>{t("dreamhome", "Löydä unelmiesi koti")}</h2>
        <p>
          {t(
            "findApartment",
            "Meiltä löydät tarpeisiisi sopivan vuokra-asunnon, opiskelija-asunnon tai senioriasunnon Porista"
          )}
        </p>
        <ApartmentSearchForm params={activeParams} noReset />
      </section>
      <AttentionBulletins />
      <h1>{title}</h1>
      {ingress && <p className="ingress">{ingress}</p>}
      <FrontPageContent content={props.content} />
    </>
  )
}

export default FrontPage

export const query = graphql`
  fragment DatoFrontPage on DatoCmsFrontpage {
    title
    ingress
    image {
      ...DatoHeroImage
    }
    mobileImage {
      ...DatoHeroMobileImage
    }
    ...DatoFrontpageContent
  }
`
