import React, { useEffect } from "react"
import { RouteComponentProps } from "@reach/router"
import useApartmentImages from "../../../../hooks/useApartmentImages"
import Spinner from "../../../atoms/Spinner"
import DefaultLayout from "../../../layouts/DefaultLayout"
import CarouselHero from "../../../organisms/carouselHero/CarouselHero"
import { AppProps } from "../../AppPage"
import { Link } from "gatsby"
import { Button } from "../../../atoms/button/Button"

type ApartmentImagesPageProps = {
  id?: string // kaikki URL:sta luettu on string
} & Omit<AppProps, "basePath"> &
  RouteComponentProps

const ApartmentImagesPage = (props: ApartmentImagesPageProps) => {
  // console.debug("Rendering ApartmentImagesPage", props)
  const [state, setId] = useApartmentImages(undefined)

  useEffect(() => {
    const apartmentId = parseInt(props.id as string, 10)
    setId(apartmentId)
  }, [props.id, setId])

  if (state.data.apartment === undefined) {
    return null
  }

  // console.debug("Rendering ApartmentImagesPage data", state)

  const propertyPageBasePath = "/fi/kohteet"

  let content
  if (state.error) {
    console.error(state.error)
    content = <p>Jokin meni pieleen</p>
  } else if (state.loading || !state.data.apartment || state.data.apartment.length < 1) {
    content = <Spinner />
  } else {
    const ap = state.data.apartment[0]
    const address = `${ap.property.street_address} ${ap.name}`
    content = (
      <>
        <h1>{address}</h1>
        <CarouselHero media={ap.media} address={address} />
        <section style={{ marginTop: "1em" }}>
          <Link to={`${propertyPageBasePath}/${ap.property.slug}`}>
            <Button label="Kiinteistön tiedot" variant="primary" displayArrow />
          </Link>
        </section>
      </>
    )
  }

  return (
    <DefaultLayout mainNav={props.mainNav} seoMetaTags={props.seoMetaTags}>
      <section>{content}</section>
    </DefaultLayout>
  )
}

export default ApartmentImagesPage
