import { RouteComponentProps, navigate } from "@reach/router"
import React, { useEffect, useRef } from "react"
import { ActionTypes } from "../../../../../context/userReducers"
import useApartmentSearch from "../../../../../hooks/useApartmentSearch"
import { objectToUrlParams } from "../../../../../utils"
import ApartmentSearchResult from "../../../../organisms/apartmentSearch/ApartmentSearchResult"
// import useUserState from "../../../../../hooks/useUserState"
import { useTranslation } from "react-i18next"
import { useAparmentSearchParams } from "../../../../../hooks/useApartmentSearchParams"
import { useUser } from "../../../../../hooks/useUserState"
import DefaultLayout from "../../../../layouts/DefaultLayout"
import ApartmentSearchForm from "../../../../organisms/forms/ApartmentSearchForm"
import { AppPageProps } from "../../../AppPage"
import * as styles from "./ApSResultPage.module.scss"

type ApSResultPageProps = RouteComponentProps & Omit<AppPageProps, "basePath" | "application">

const ApSResultPage = (props: ApSResultPageProps) => {
  // console.debug("Rendering ApartmentSearchResultPage", props)
  const { t } = useTranslation("apartmentSearchForm")

  const isMounted = useRef(false)

  // Käyttäjän asetukset ja tila, globaali UserContext
  // Tässä komponentissa meitä kiinnostaa userState.apartmentSearch
  const [userState, userDispatch] = useUser()
  // const userState = useUserState()

  // Käyttäjän tilan ja URLista luettujen arvojen summa.
  // Pidetään erikseen käyttäjän tilasta koska rakenne eri ja muutoksiin pitää reagoida eri tilanteissa
  // activeParams on normalisoitu userState.apartmentSearch.params ja URL search parametrien yhdistelmä
  const [activeParams, paramDispatch, isUrlReset] = useAparmentSearchParams(
    userState.apartmentSearch.params,
    props.location?.search
  )

  // Välityspalvelimen asuntohakupalvelu:
  // - searchState on verkkopyynnön tila ja vastauksen data
  // - searchFetch() tekee API kutsun
  const [searchState, searchFetch] = useApartmentSearch()

  // console.debug("ApartmentSearchResultPage", userState, activeParams, searchState)

  // ilmoittaa UserContextille että hakutulosten haluttu esitystapa (kartta vai lista) muuttui
  // function setResultMode(value: SearchResultMode): void {
  //   // console.debug("Rendering ApartmentSearchResultList: changin result type to", value)
  //   userDispatch({ type: ActionTypes.SetApartmentSearchResultMode, payload: value })
  // }

  // päivitä activeParams kun käyttäjän tilassa tapahtuu muutos
  // tämä kuljettaa myös hakulomaakeen nollauksen eteenpäin (form reset -> userState.apartmentSearch.params = {} -> tämä laukeaa)
  useEffect(() => {
    if (!isMounted.current) {
      return
    }
    // console.debug("Päivitä activeParams", userState.apartmentSearch.params)
    paramDispatch({
      type: "set",
      payload: {
        userParams: userState.apartmentSearch.params,
        // urlin parametreistä ei välitetä enää tässä vaiheessa
      },
    })
  }, [paramDispatch, userState.apartmentSearch.params])

  useEffect(() => {
    if (!isMounted.current) {
      if (isUrlReset) {
        // Käyttäjän parameterit ja url parametrit yhdessä tulkittiin niin, että halutaan resetoida lomake
        // Asetetaan useAparmentSearchParams() hookin tulkkaamat uudet parametrit käyttäjän tilaan, joka laukaisee päivitykset oikeissa paikoissa
        userDispatch({ type: ActionTypes.ResetApartmentSearchParams, payload: activeParams })
      }
      return // älä tee muuta ekalla kutsukerralla
    }

    // console.debug("ApartmentSearchResultPage useEffect 1, URL päivitys/uudelleenohjaus", activeParams)

    if (activeParams === undefined) {
      // päivitä URL queryparametrit ilman navigointia uudelle sivulle (replace)
      // navigate(`?resultMode=${userState.apartmentSearch.resultMode}&free=1`, { replace: true })
      navigate(`?free=1`, { replace: true })
    } else {
      // console.debug("ApartmentSearchResultPage useEffect 1", activeParams)
      // tee lomakkeen arvoista URL-parametri lista navigaatiota varten. Parameterit välitetään myös URLissä jotta siitä tulee
      // jaettava eikä hakukriteerit ole vain piilossa
      const queryParams = objectToUrlParams(activeParams as Record<string, string>) // TODO onko "as" tässä ok?

      // päivitä URL queryparametrit ilman navigointia uudelle sivulle (replace)
      // navigate(`?resultMode=${userState.apartmentSearch.resultMode}&${queryParams}`, { replace: true })
      // console.debug("Navigate to", queryParams)
      navigate(`?${queryParams}`, { replace: true })
    }
  }, [activeParams, isUrlReset, userDispatch])

  // useEffect(() => {
  //   console.debug("activeParams change", activeParams)
  // }, [activeParams])

  // tee uusi API kutsu kun hakuparameterit muuttuvat
  useEffect(() => {
    // console.debug("ApartmentSearchResultPage useEffect 2, tee haku jos tarve", activeParams)

    if (activeParams === undefined) {
      // console.debug("Skipping API call. No search params")
      return
    }

    searchFetch(activeParams)
  }, [activeParams, searchFetch])

  useEffect(() => {
    isMounted.current = true
  }, [])

  if (searchState.error) {
    return (
      <p>
        Error: <pre>{searchState.error.message}</pre>
      </p>
    )
  }

  return (
    <DefaultLayout seoMetaTags={props.seoMetaTags} mainNav={props.mainNav}>
      <section className={`full-bleed ${styles.header}`}>
        <details>
          <summary>{t("Muokkaa hakua", "Muokkaa hakua")}</summary>
          {!searchState.loading && <ApartmentSearchForm params={activeParams} />}
        </details>
      </section>
      <section>
        <h1 className={styles.custom_h1}>{t("Hakutulokset", "Hakutulokset")}</h1>
        <ApartmentSearchResult
          loading={searchState.loading}
          apartments={searchState.data.apartment}
          totalCount={searchState.data.apartment_aggregate.aggregate.count}
          // resultMode={userState.apartmentSearch.resultMode}
          // setResultMode={setResultMode}
        />
      </section>
    </DefaultLayout>
  )
}

export default ApSResultPage
