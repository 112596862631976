import { graphql } from "gatsby"
import React from "react"
import ContentCtaButtonIconSection from "./modular-blocks/ContentCtaButtonIconSection"
import ContentCtaCardSection from "./modular-blocks/ContentCtaCardSection"
import ContentImage from "./modular-blocks/ContentImage"
import ContentResidentInfoSection from "./modular-blocks/ContentResidentInfoSection"
import ContentTextSection from "./modular-blocks/ContentTextSection"

export type FrontPageContentProps = Queries.DatoFrontpageContentFragment

const FrontPageContent = (props: FrontPageContentProps) => {
  // console.debug("Rendering FrontPageContent", props)
  const { content } = props
  if (!content) {
    console.warn("Invalid props for FrontPageContent")
    return null
  }

  return (
    <>
      {content.map(field => {
        if (!field) {
          return null
        }
        switch (field.__typename) {
          case "DatoCmsCtaButtonIconSection":
            return <ContentCtaButtonIconSection key={field.id} {...field} />
          case "DatoCmsCtaCardSection":
            return <ContentCtaCardSection key={field.id} {...field} />
          case "DatoCmsTextSection":
            return <ContentTextSection key={field.id} {...field} />
          case "DatoCmsImage":
            return <ContentImage key={field.id} {...field} />
          case "DatoCmsResidentInfoSection":
            return <ContentResidentInfoSection key={field.id} {...field} />
        }
      })}
    </>
  )
}

export default FrontPageContent

export const query = graphql`
  fragment DatoFrontpageContent on DatoCmsFrontpage {
    content {
      ... on Node {
        id
        __typename
      }
      ...ContentTextSection
      ...ContentCtaButtonIconSection
      ...ContentCtaCardSection
      ...ContentImage
      ...ContentResidentInfoSection
    }
  }
`
