import { graphql } from "gatsby"
import React from "react"
import ContactPageContent from "../dato/ContactPageContent"

import AttentionBulletins from "../dato/AttentionBulletins"
import ContactLocation from "../dato/ContactLocation"
import ContentContactCollectionDataset from "../dato/datasets/ContentContactCollectionDataset"
import HeroImage from "../organisms/HeroImage"

type ContactPageProps = Queries.DatoContactPageFragment

const ContactPage = (props: ContactPageProps) => {
  // console.debug("Rendering template ContactPage", props)
  const { content, title, ingress, image, contactLocationRef, ...rest } = props

  if (!content || !title) {
    console.warn("Invalid props for ContactPage")
    return null
  }
  return (
    <>
      <HeroImage image={image} />
      <AttentionBulletins />

      <h1>{title}</h1>
      {ingress && <p className="ingress">{ingress}</p>}

      <ContactLocation content={contactLocationRef} />

      <ContactPageContent content={content} />
      <ContentContactCollectionDataset {...rest} />
    </>
  )
}

export default ContactPage

export const query = graphql`
  fragment DatoContactPage on DatoCmsContactpage {
    title
    ingress
    image {
      ...DatoHeroImage
    }
    contactLocationRef {
      ...ContactLocation
    }
    ...DatoContactpageContent
    ...ContentContactCollectionDataset
  }
`
