import { graphql, Link } from "gatsby"
import React from "react"
import { Button } from "../atoms/button/Button"
import DatoCampaignPageContent from "../dato/CampaignPageContent"
import CampaignHeroImage from "../organisms/CampaignHeroImage"
// import * as styles from "./CampaignPage.module.scss"

type CampaignPageProps = Queries.DatoCampaignPageFragment & {
  /** otettu mukaan jotta voidaan tehdä kampanjapohjaista logiikkaa */
  basePath: string
}

/**
 * Kampanjasivu, yleisesti.
 * Käytetään alkuun vain Kierrättäjäpersoona kampanjaassa
 */
const CampaignPage = (props: CampaignPageProps) => {
  console.debug("Rendering template CampaignPage", props)
  const { content, title, ingress, image } = props
  if (!content || !title) {
    console.warn("Invalid props for CampaignPage")
    return null
  }

  return (
    <>
      <CampaignHeroImage image={image} />

      <h1 style={{ textAlign: "center", maxWidth: "800px" }}>{title}</h1>
      {ingress && (
        <p className="ingress" style={{ textAlign: "center", maxWidth: "800px" }}>
          {ingress}
        </p>
      )}

      <DatoCampaignPageContent content={props.content} />
      {props.basePath === "/fi/kierrattaja" && (
        <div style={{ display: "flex", justifyContent: "center", paddingTop: "1rem", paddingBottom: "2rem" }}>
          <Link to="/fi/kierrattaja/kysymys/1">
            <Button label={"Aloita testi tästä"} displayArrow />
          </Link>
        </div>
      )}

      {props.basePath === "/fi/kierrattaja/kierrattaja-kiitos" && (
        <div style={{ display: "flex", justifyContent: "center", paddingTop: "1rem", paddingBottom: "2rem" }}>
          <Link
            target="_blank"
            to="https://keraamo.fi/lajittelu-ja-neuvonta/lajitteluohjeet/?_gl=1*ofl0ra*_up*MQ..*_ga*MTg4NTUzNDUwOC4xNjk1NzM0ODEy*_ga_587WKTQQQY*MTY5NTczNDgxMS4xLjEuMTY5NTczNDgxOC4wLjAuMA">
            <Button label={"Katso täältä kätevät jätteiden lajitteluohjeet"} displayArrow />
          </Link>
        </div>
      )}
    </>
  )
}

export default CampaignPage

export const query = graphql`
  fragment DatoCampaignPage on DatoCmsCampaignPage {
    title
    ingress
    image {
      ...DatoCampaignHeroImage
    }
    ...DatoCampaignPageContent
  }
`
