import { EffectCallback, useEffect, useRef } from "react"

/**
 * Modified version of useEffect that's executed only one time, at the mounting time
 * @see https://usehooks-ts.com/react-hook/use-effect-once
 */
function useEffectOnce(effect: EffectCallback) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const destroyFunc = useRef<void | any>()
  const calledOnce = useRef(false)
  const renderAfterCalled = useRef(false)

  if (calledOnce.current) {
    renderAfterCalled.current = true
  }

  useEffect(() => {
    if (calledOnce.current) {
      return
    }

    calledOnce.current = true
    destroyFunc.current = effect()

    return () => {
      if (!renderAfterCalled.current) {
        return
      }

      if (destroyFunc.current) {
        destroyFunc.current()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useEffectOnce
