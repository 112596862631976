import { graphql } from "gatsby"
import React from "react"
import ArticlePageContent from "../dato/ArticlePageContent"
import HeroImage from "../organisms/HeroImage"

type ArticlePageProps = Queries.DatoArticlePageFragment

const ArticlePage = (props: ArticlePageProps) => {
  // console.debug("Rendering template Articlepage", props)
  const { content, title, ingress, image, mobileImage } = props
  if (!content || !title) {
    console.warn("Invalid props for ArticlePage")
    return null
  }

  return (
    <>
      <HeroImage image={image} mobileImage={mobileImage} />

      <h1>{title}</h1>
      {ingress && <p className="ingress">{ingress}</p>}

      <ArticlePageContent content={props.content} />
    </>
  )
}

export default ArticlePage

export const query = graphql`
  fragment DatoArticlePage on DatoCmsArticlepage {
    title
    ingress
    image {
      ...DatoHeroImage
    }
    mobileImage {
      ...DatoHeroMobileImage
    }

    ...DatoArticlepageContent
  }
`
