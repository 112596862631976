import React from "react"
import { graphql } from "gatsby"
import Markdown, { MarkdownRemarkFragment } from "../markdown/Markdown"
import ContentContactCollection, { ContentContactCollectionFragment } from "../modular-blocks/ContentContactCollection"
import * as styles from "./ContentContactCollectionDataset.module.scss"

type ContentContactCollectionDatasetProps = ContentContactCollectionDatasetFragment

const ContentContactCollectionDataset = (props: ContentContactCollectionDatasetProps) => {
  const { contactsTextNode, contactsHeading, contactCollections } = props
  const htmlAst = contactsTextNode?.childMarkdownRemark?.htmlAst

  if (!contactsHeading) {
    console.warn("Invalid porps for ContentContactsCollectionSection")
    return null
  }

  return (
    <section className={styles.container}>
      <div className="narrow-content">
        <h2>{contactsHeading}</h2>
        {htmlAst && <Markdown headingShift={3} htmlAst={htmlAst} />}
        {contactCollections?.map(contact => {
          return <ContentContactCollection key={contact.id} data={contact} />
        })}
      </div>
    </section>
  )
}

export default ContentContactCollectionDataset

export type ContentContactCollectionDatasetFragment = {
  contactsHeading?: string
  contactsTextNode?: MarkdownRemarkFragment
  contactCollections: ContentContactCollectionFragment[]
}

export const query = graphql`
  fragment ContentContactCollectionDataset on DatoCmsContactpage {
    contactsHeading
    contactsTextNode {
      ...MarkdownRemark
    }
    contactCollections {
      ...ContentContactCollection
    }
  }
`
