import React from "react"
import { graphql } from "gatsby"
import * as styles from "./ContentContactCollection.module.scss"

type ContentContactCollectionProps = { data: ContentContactCollectionFragment }

const ContentContactCollection = ({ data }: ContentContactCollectionProps) => {
  // const roles = data.contactPersons.map(contact => contact.roles).join()

  if (!data) {
    return null
  }

  return (
    <dl className={styles.container}>
      <dt>{data.name}</dt>

      {data.contactPersons?.map(contact => (
        <dd key={contact.id}>
          <span>
            {contact.firstname} {contact.lastname}
            {/* {contact.role && `, ${contact.role}`} */}
            {/* TODO: no "," when null */}
            {contact.roles && contact.roles.length > 0 ? ", " : ""}
            {contact.roles &&
              contact.roles.map((role, i) => (
                <>
                  {role.role}
                  {i + 1 !== contact.roles?.length ? ", " : ""}
                </>
              ))}
          </span>
          {contact.phone && <a href={`tel:${contact.phone.replace(/ /g, "")}`}>{contact.phone}</a>}
        </dd>
      ))}
    </dl>
  )
}

export default ContentContactCollection

export type ContentContactCollectionFragment = {
  __typename: "DatoCmsContactCollection"
  id: string
  name?: string
  contactPersons: Array<{
    id: string
    firstname?: string
    lastname?: string
    role?: string
    roles?: string[]
    unit?: string
    phone?: string
    email?: string
  }>
}

export const query = graphql`
  fragment ContentContactCollection on DatoCmsContactCollection {
    __typename
    id
    name
    contactPersons {
      id
      firstname
      lastname
      role
      roles {
        role
      }
      unit
      phone
      email
    }
  }
`
