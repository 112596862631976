import { useStaticQuery, graphql } from "gatsby"

type TampuuriHoodsData = {
  tampuuri: {
    property: readonly {
      hood: string
    }[]
  }
}

export const useTampuuriHoods = (): string[] => {
  const data = useStaticQuery<TampuuriHoodsData>(
    graphql`
      query TampuuriHoods {
        tampuuri {
          property(distinct_on: hood, order_by: { hood: asc }) {
            hood
          }
        }
      }
    `
  )

  const hoods = data.tampuuri.property
  if (!hoods) {
    throw new Error("Failed to fetch list of neighborhood names")
  }

  return hoods.map(hood => hood.hood as string)
}
