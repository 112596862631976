import { graphql } from "gatsby"
import { HelmetDatoCmsTags } from "gatsby-source-datocms"
import React from "react"
import ApartmentImagesHomePage from "./app-pages/ApartmentImagesHomePage"
import RecyclePersonalityTest from "./app-pages/RecyclePersonaliltyTest"
import ApartmentSearchPage from "./app-pages/apartmentSearch/ApartmentSearchPage"

/**
 * Päättää mikä Dato CMS Sovellussivuista rendataan
 */

/**
 * Propsit jotka annetaan eteenpäin kaikille Sovellussivun implementoinneille
 */
export type AppPageProps = Queries.DatoAppPageFragment & {
  basePath: string
  seoMetaTags: HelmetDatoCmsTags
  mainNav: Queries.DatoMainNavTreeFragment
}

/**
 * Propsit jotka annetaan eteenpäin kaikille Sovellussivun implementoinneille
 */
export type AppProps = Omit<AppPageProps, "application">

const AppPage = (props: AppPageProps) => {
  const { application, ...appProps } = props

  switch (application) {
    case "Asuntohaku":
      return <ApartmentSearchPage {...appProps} />
    case "Asuntokuvat":
      return <ApartmentImagesHomePage {...appProps} />
    case "Kierrättäjäpersoona":
      return <RecyclePersonalityTest {...appProps} />
    default:
      console.error(`Unknown Application "${application}"!`, props)
  }

  return null
}

export default AppPage

export const query = graphql`
  fragment DatoAppPage on DatoCmsAppPage {
    application
    parameters
    title
    ingress
    image {
      ...DatoHeroImage
    }
  }
`
