import { navigate } from "gatsby"
import React, { useState, useEffect, useRef } from "react"
import useApi, { isApiStateError, isApiStateSuccess } from "../../../hooks/useApi"
import { Button } from "../../atoms/button/Button"
import Checkbox from "../../atoms/form/Checkbox"
import TextInput from "../../atoms/form/TextInput"
import Spinner from "../../atoms/Spinner"
// import * as styles from "./RecyclePersonalityForm.module.scss"

type RecyclePersonalityFormProps = {}

/**
 * Type guard for Element objects to narrow down to those
 * that have `validity` read-only property aka HTMLObjectElements
 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLObjectElement/validity
 *
 * @param e Element
 * @returns true if element has `validity` read-only property
 */
function isObjectElement(e: Element): e is HTMLObjectElement {
  return "validity" in e
}

function isFormValid(form: HTMLFormElement | null) {
  if (!form) {
    return false
  }
  const fields = Array.from(form.elements).filter(isObjectElement)
  const invalidFields = fields.filter(e => !e.validity.valid)
  return invalidFields.length < 1
}

const TOKEN = "XboR$M3*BA&oS7x7v$"

type ApiResponse = {
  save: string
}

const RecyclePersonalityForm = (props: RecyclePersonalityFormProps) => {
  /**
   * TODO:
   * - onko käyttäjä täyttänyt testin? jos ei, ohjaa alkusivulle?
   * - säännöt: uusi sivu? modal?
   */

  const formRef = useRef<HTMLFormElement | null>(null)
  const [formIsValid, setFormIsValid] = useState(false)
  const [fieldValues, setFieldValues] = useState({
    firstName: "",
    lastName: "",
    street: "",
    zip: "",
    city: "",
    phone: "",
    email: "",
    piilohunaja: "", // this needs to be empty string or else backend will reply with 422 error "Seems like spam"
    concent: false,
  })
  const [makeApiRequest, apiState] = useApi<ApiResponse>("registration")

  useEffect(() => {
    setFormIsValid(isFormValid(formRef.current))
  }, [fieldValues])

  const onInputValueChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target

    setFieldValues({
      ...fieldValues,
      [name]: value,
    })
  }
  const onCheckboxValueChange = (name: string, checked: boolean) => {
    setFieldValues({
      ...fieldValues,
      [name]: checked,
    })
  }

  const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()

    console.debug("FormData", fieldValues)
    if (!formIsValid || !fieldValues) {
      return
    }

    // Add preshared "secret" token and send to backend API
    makeApiRequest({ ...fieldValues, token: TOKEN })
  }

  if (apiState.fetchState === "done" && isApiStateSuccess(apiState)) {
    // Heitä käyttäjä kiitossivulle.
    // Korvaa lomakesivu selainhistoriassa
    // => takaisinnavigaatio palauttaa edelliselle sivulle eli persoonasivulle
    navigate("/fi/kierrattaja/kierrattaja-kiitos", { replace: true })
    return
  }

  const hasApiError = isApiStateError(apiState)

  return (
    <form
      onSubmit={onSubmit}
      autoComplete="on"
      id="recyclePersonalityForm"
      ref={formRef}
      style={{ width: "100%", maxWidth: 600, margin: "auto" }}>
      <TextInput
        name="firstName"
        label="Etunimi"
        required={true}
        onChange={onInputValueChange}
        value={fieldValues.firstName}
      />
      <TextInput
        name="lastName"
        label="Sukunimi"
        required={true}
        onChange={onInputValueChange}
        value={fieldValues.lastName}
      />
      <TextInput
        name="street"
        label="Katuosoite"
        required={true}
        onChange={onInputValueChange}
        value={fieldValues.street}
      />
      <TextInput
        name="zip"
        label="Postinumero"
        required={true}
        inputMode="numeric"
        onChange={onInputValueChange}
        value={fieldValues.zip}
      />
      <TextInput
        name="city"
        label="Postitoimipaikka"
        required={true}
        onChange={onInputValueChange}
        value={fieldValues.city}
      />
      <TextInput
        name="phone"
        label="Puhelinnumero"
        required={true}
        inputMode="tel"
        onChange={onInputValueChange}
        value={fieldValues.phone}
      />
      <TextInput
        name="email"
        label="Sähköposti"
        required={true}
        inputMode="email"
        onChange={onInputValueChange}
        value={fieldValues.email}
      />
      <input
        type="text"
        name="piilohunaja"
        value={fieldValues.piilohunaja}
        style={{ display: "none" }}
        onChange={onInputValueChange}
      />
      <div style={{ margin: "1rem 0" }}>
        <Checkbox
          name="concent"
          value="yes"
          onChange={checked => onCheckboxValueChange("concent", checked)}
          checked={fieldValues.concent}
          required>
          Olen lukenut ja hyväksynyt kilpailun{" "}
          <a href="/fi/kierrattaja/kierrattaja-saannot" target="_blank">
            säännöt
          </a>
        </Checkbox>
      </div>
      <Button
        type="submit"
        variant="primary"
        onClick={() => {}}
        label="Lähetä"
        disabled={!formIsValid || apiState.error !== undefined || apiState.fetchState !== "init"}
      />
      {apiState.fetchState === "loading" && <Spinner />}
      {hasApiError && <p className="error">Jokin meni pieleen lomakkeen käsittelyssä.</p>}
      {/* <h2>Debug</h2>
      <pre>{JSON.stringify(apiState, null, 2)}</pre> */}
    </form>
  )
}

export default RecyclePersonalityForm
