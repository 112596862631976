// extracted by mini-css-extract-plugin
export var checkboxBlock = "ApartmentSearchForm-module--checkboxBlock--9dfb4";
export var checkboxBlockFlex = "ApartmentSearchForm-module--checkboxBlockFlex--563ee";
export var container = "ApartmentSearchForm-module--container--ae9b9";
export var controls = "ApartmentSearchForm-module--controls--2e36d";
export var flexContainer = "ApartmentSearchForm-module--flexContainer--fccbf";
export var hideOnDesktop = "ApartmentSearchForm-module--hideOnDesktop--b7c70";
export var hideOnMobile = "ApartmentSearchForm-module--hideOnMobile--f3c7e";
export var inputs = "ApartmentSearchForm-module--inputs--3e89b";
export var noLegend = "ApartmentSearchForm-module--noLegend--069c0";
export var reset = "ApartmentSearchForm-module--reset--dde4f";
export var select = "ApartmentSearchForm-module--select--3eca4";
export var wide = "ApartmentSearchForm-module--wide--86aac";