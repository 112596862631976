import { graphql } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"

type CampaignHeroImageProps = {
  image: Queries.DatoCampaignHeroImageFragment | null
}

const CampaignHeroImage = (props: CampaignHeroImageProps) => {
  // console.debug("Rendering HeroImage", props)
  const { image } = props

  if (!image) {
    return null
  }

  const objectPosition = image.focalPoint ? `${image.focalPoint.x * 100}% ${image.focalPoint.y * 100}%` : "50% 50%"
  return (
    <section style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "500px" }}>
      <GatsbyImage
        alt={image.alt || ""}
        style={{ height: 400, objectFit: "cover" }}
        image={image.gatsbyImageData as unknown as IGatsbyImageData}
        imgStyle={{ objectPosition }}
        loading="eager"
      />
    </section>
  )
}

export default CampaignHeroImage

export const query = graphql`
  fragment DatoCampaignHeroImage on DatoCmsFileField {
    alt
    gatsbyImageData(layout: CONSTRAINED, height: 400)
    focalPoint {
      x
      y
    }
  }
`
