import { graphql } from "gatsby"
import React from "react"
// import ContentCtaButtonIconSection from "./modular-blocks/ContentCtaButtonIconSection"
import ContentCtaCardSection from "./modular-blocks/ContentCtaCardSection"
import ContentImage from "./modular-blocks/ContentImage"
import ContentTextSection from "./modular-blocks/ContentTextSection"
// import ContentResidentInfoSection from "./modular-blocks/ContentResidentInfoSection"

type ContactPageContentProps = Queries.DatoContactpageContentFragment

const ContactPageContent = ({ content }: ContactPageContentProps) => {
  // console.debug("Rendering ContactPageContent", props)
  if (!content) {
    console.warn("Invalid props for ContactPageContent")
    return null
  }

  return (
    <>
      {content.map(field => {
        if (!field) {
          return null
        }
        switch (field.__typename) {
          // case "DatoCmsCtaButtonIconSection":
          //   return <ContentCtaButtonIconSection key={field.id} {...field} />
          case "DatoCmsCtaCardSection":
            return <ContentCtaCardSection key={field.id} {...field} />
          case "DatoCmsTextSection":
            return <ContentTextSection key={field.id} {...field} />
          case "DatoCmsImage":
            return <ContentImage key={field.id} {...field} />
          // case "DatoCmsResidentInfoSection":
          //   return <ContentResidentInfoSection key={field.id} {...field} />
        }
      })}
    </>
  )
}

export default ContactPageContent

export const query = graphql`
  fragment DatoContactpageContent on DatoCmsContactpage {
    content {
      ... on Node {
        __typename
        id
      }
      ...ContentTextSection
      ...ContentCtaCardSection
      ...ContentImage
    }
  }
`
