import React, { useEffect, useState } from "react"
import { RecyclePersonaliltyQuestion } from "../pages/app-pages/recyclePersonaliltyTestData"
import { RouteComponentProps } from "@reach/router"
// import CampaignHeroImage, { DatoHeroImageFragment } from "./HeroImage"
import questionHero from "../../images/recycle-personality-question-hero.png"
import infoIcon from "../../images/recycle-personality-info-icon-1.png"
import { Button } from "../atoms/button/Button"
import { Link, navigate } from "gatsby"
import { Answer, isRecyclerState, RecyclerState } from "../pages/app-pages/RecyclePersonaliltyTest"
import useUpdateEffect from "../../hooks/useUpdateEffect"
import useEffectOnce from "../../hooks/useEffectOnce"
import AnswerButton from "../atoms/button/AnswerButton"
// import * as styles from "./RecyclePersonalityQuestion.module.scss"

type RecyclePersonalityQuestionProps = RouteComponentProps & {
  data: RecyclePersonaliltyQuestion
  /* same as path by convention */
  questionNumber: string
}

// localStoragesta: const state: RecyclerState = {
//   "1": { id: "roskis", points: 0 },
//   "2": null,
//   "3": null,
//   "4": null,
//   "5": null,
//   ...jatkuu,
// }

const CAMPAIGN_ROOT_PATH = "/fi/kierrattaja"

// Täytyy olla järjestyksessä suurimmasta pistemäärästä pienimpään!
// kovaakoodattu mäppäys minimipistemäärästä Datossa olevan tulossivun reitin slugiin koska kiire
const personalityMap = [
  [22, "kaatopaiden-kerailija"],
  [19, "tolkkien-huuhtelija"],
  [15, "pahvinlitistaja"],
  [12, "paperinpyorittaja"],
  [9, "paan-pensaaseen"],
  [0, "pullonpalauttaja"],
] as const

function hasPreviousAnswers(qNum: string, state: RecyclerState): boolean {
  const num = parseInt(qNum, 10)
  if (isNaN(num) || num < 1) {
    return false
  }
  for (let i = 1; i < num; i++) {
    if (!(`${i}` in state)) {
      console.debug(`Answer to previous question "${i}" not in state. Should have everything up to ${num}`, state)
      return false
    }
  }
  return true
}

/**
 * Laske kokonaispisteet käyttäjän vastauksista
 * @param recyclerState
 */
function calcPoints(recyclerState: RecyclerState): number {
  const answers = Object.values(recyclerState)
  return answers.reduce((acc, curAnswer) => (curAnswer !== null ? acc + curAnswer.points : acc), 0)
}

/**
 *
 * @param total
 * @returns
 */
function getPersonalitySlug(total: number): string | null {
  // TODO etsi personalityMap listasta eka jossa vaadittava pistemäärä yhtä suuri tai pienempi kuin pelaajan "total" pistemäärä
  const res = personalityMap.find(([minPoints, _]) => minPoints <= total)
  // console.debug(`Finding URL slug for total points "${total}"`, personalityMap, res)
  return res ? res[1] : null
}

function getNextUrl(questionNumber: string, recyclerState: RecyclerState | null): string | null {
  // jos recycleState === null palauta aloitus url
  if (recyclerState === null) {
    console.warn("getNextUtl(): Cannot generate next URL. recycleState is null")
    return null
  }

  // jos viimeisessä kysymyksessä, palauta persoonasivun url
  if (questionNumber === "8") {
    const total = calcPoints(recyclerState)
    const slug = getPersonalitySlug(total)
    // console.debug(`getNextUtl(): total points "${total}" => slug "${slug}"`)
    return slug ? `${CAMPAIGN_ROOT_PATH}/${slug}` : null
  }

  return `${CAMPAIGN_ROOT_PATH}/kysymys/${parseInt(questionNumber, 10) + 1}`
}

const RecyclePersonalityQuestion = (props: RecyclePersonalityQuestionProps) => {
  // const { image } = props
  // TODO: localStoragen state --> onko pelaaja aloittanut pelin? Jos ei niin etusivulle
  // Varmistetaan edellisten kysymysten state (null vai numero?)
  // Info + kuva

  const [answer, setAnswer] = useState<Answer | null>(null)
  const [recyclerState, setRecyclerState] = useState<RecyclerState | null>(null)
  const [nextUrl, setNextUrl] = useState<string | null>(null)

  const { data, questionNumber } = props
  const { answers } = data

  // run once after render
  useEffectOnce(() => {
    const raw = localStorage.getItem("recycler")
    const items = raw && JSON.parse(raw || "")
    if (items) {
      if (!isRecyclerState(items)) {
        // error
        // TODO what to do when invalid state
        // localStorage.setItem("recycler", JSON.stringify(INIT_STATE))
        // setRecyclerState(INIT_STATE)
        return
      }
      // TODO aseta valituksi vastaukseksi statesta oleva jos siellä sellainen on => jos käyttäjä tulee sivulle johon on jo vastannut
      setRecyclerState(items)
    }
  })

  useUpdateEffect(() => {
    const oldState = recyclerState
    const newState = {
      ...oldState,
      [questionNumber]: answer,
    }

    console.debug("RecyclePersonalityQuestion useEffect() answer changed", recyclerState, newState)

    setRecyclerState(newState)
  }, [answer])

  // run every time recyclerState changes
  useUpdateEffect(() => {
    // has player even started the test?
    if (recyclerState === null) {
      // ...no! Redirect to campaign start page
      console.debug("RecyclePersonalityQuestion useEffect() navigating to start on null state", recyclerState)
      navigate(CAMPAIGN_ROOT_PATH)
      return
    }

    // does player have answers to all previous questions?
    if (!hasPreviousAnswers(questionNumber, recyclerState)) {
      // ...no! Redirect to campaign start page
      console.debug("RecyclePersonalityQuestion useEffect() navigating to start on missing answers", recyclerState)
      navigate(CAMPAIGN_ROOT_PATH)
      return
    }

    // TODO jos käyttäjä tulee sivulle johon on jo vastannut.
    // Aseta valituksi vastaukseksi statesta oleva jos
    // - siellä sellainen on
    // - se on eri kuin answer

    // everything OK, store new state to browser
    localStorage.setItem("recycler", JSON.stringify(recyclerState))
  }, [recyclerState])

  useEffect(() => {
    console.debug("RecyclePersonalityQuestion useEffect() calculating next page URL", recyclerState)
    setNextUrl(getNextUrl(props.questionNumber, recyclerState))
  }, [recyclerState])

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: 800, margin: "auto" }}>
      <div
        style={{
          display: "flex",
          alignSelf: "center",
          height: "500px",
        }}>
        <img
          src={questionHero}
          alt=""
          style={{
            height: "400px",
            alignSelf: "center",
          }}
        />
      </div>
      <p>{`Kysymys ${props.path}/8`}</p>
      <h2 style={{ textAlign: "center" }}>{data.question}</h2>
      <div>
        <ul
          style={{
            display: "flex",
            flexFlow: "column",
            gap: "0.5rem",
            padding: 0,
            maxWidth: "600px",
          }}>
          {answers.map(itm => {
            return (
              <li
                key={itm.id}
                style={{
                  listStyle: "none",
                }}>
                <AnswerButton
                  disabled={answer !== null}
                  isClicked={answer && answer.id === itm.id}
                  onClick={() => {
                    setAnswer({ id: itm.id, points: itm.points })
                  }}>
                  {itm.answer}
                </AnswerButton>
              </li>
            )
          })}
        </ul>
      </div>

      {answer !== null && (
        <>
          <div style={{ display: "flex", justifyContent: "center", paddingTop: "1rem", paddingBottom: "2rem" }}>
            <Link to={nextUrl || "#"}>
              <Button
                label={props.questionNumber === "8" ? "Katso tulos" : "Seuraava"}
                displayArrow
                disabled={nextUrl === null}
              />
            </Link>
          </div>
          <div style={{ display: "flex", alignItems: "center", maxWidth: "600px" }}>
            <img src={infoIcon} alt="" style={{ width: "20%", minWidth: "150px" }} />
            <div style={{ textAlign: "center" }}>
              <h2>Info</h2>
              <p>{data.info}</p>
              <p style={{ fontSize: "0.75rem" }}>
                Lähde:{" "}
                <a href="https://rinkiin.fi/" target="_blank" rel="noreferrer">
                  www.rinkiin.fi
                </a>
              </p>
            </div>
          </div>
        </>
      )}

      {/* <h3 style={{ marginTop: "4rem" }}>Debug</h3>
      <pre>answer: {JSON.stringify(answer, null, 2)}</pre>
      <pre>recyclerState: {JSON.stringify(recyclerState, null, 2)}</pre> */}
    </div>
  )
}

export default RecyclePersonalityQuestion
