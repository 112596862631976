import React, { useContext, useEffect, useRef, useState } from "react"
import { FaFacebookF, FaLinkedinIn, FaShareAlt, FaTwitter } from "react-icons/fa"
import { IoLink } from "react-icons/io5"
import { MdEmail } from "react-icons/md"
import useOnClickOutside from "../../hooks/useOnClickOutside"
import useSsr from "../../hooks/useSsr"
import { Button } from "../atoms/button/Button"
import * as styles from "./ShareLinks.module.scss"

export type ShareLinksProps = {
  title: string
  text: string
  url?: string
  labels?: {
    share?: string
    copyLink?: string
    email?: string
  }
}

const ShareLinks: React.FC<ShareLinksProps> = props => {
  // console.debug('Rendering ShareLinks', props)

  const containerRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const { isBrowser } = useSsr()

  // if not in browser, no need to render anything
  if (!isBrowser) return null

  /* Jaettavat sisällöt */
  const { url = window.location.href, title, text, labels } = props

  const onShareIntent = () => {
    if (navigator.share) {
      // native Share API is supported, use that
      navigator
        .share({
          title,
          text,
          url,
        })
        .then(() => {
          console.info("Successfully shared")
        })
        .catch(error => {
          console.error("Something went wrong sharing the blog", error)
        })
    } else {
      // native Share API not supported, open plain old links menu
      setIsOpen(!isOpen)
    }
  }

  useOnClickOutside(containerRef, () => setIsOpen(false))

  return (
    <div className={styles.container} ref={containerRef}>
      <Button
        variant="secondary"
        label={
          <>
            {props.labels?.share || "Jaa"} <FaShareAlt />
          </>
        }
        onClick={onShareIntent}
      />
      {isOpen && (
        <div className={styles.links}>
          <a className={styles.link} onClick={() => navigator.clipboard.writeText(url)} style={{ cursor: "pointer" }}>
            <IoLink />
            <span>{props.labels?.copyLink || "Kopioi linkki"}</span>
          </a>
          <a
            className={styles.link}
            target="_blank"
            href={encodeURI(`mailto:subject=${title}&body=${text}%0A%0A${url}`)}>
            <MdEmail />
            <span>{props.labels?.email || "Lähetä sähköposti"}</span>
          </a>
          <a
            className={styles.link}
            target="_blank"
            href={encodeURI(`https://www.facebook.com/sharer/sharer.php?u=${url}`)}>
            <FaFacebookF />
            <span>Facebook</span>
          </a>
          {/* <a className={styles.link} href="">
          <FaInstagram />
            <span>Instagram</span>
          </a> */}
          <a
            className={styles.link}
            target="_blank"
            href={encodeURI(
              `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${text}`
            )}>
            <FaLinkedinIn />
            <span>LinkedIn</span>
          </a>
          {/* <a className={styles.link} target="_blank" href={`https://pinterest.com/pin/create/button/?url=${imagesource}&media=${imageurl}&description=${teksti}`}>
            <FaPinterest />
            <span>Pinterest</span>
          </a> */}
          <a
            className={styles.link}
            target="_blank"
            href={encodeURI(`https://twitter.com/intent/tweet?text=${title}%0A%0A${text}%0A%0A${url}`)}>
            <FaTwitter />
            <span>Twitter</span>
          </a>
        </div>
      )}
    </div>
  )
}

export default ShareLinks
