import React from "react"
import * as styles from "./Spinner.module.scss"

type SpinnerProps = {
  size?: "medium" | "contained"
}

const Spinner = (props: SpinnerProps) => {
  const { size = "medium" } = props

  let s = "46px"
  if (size === "contained") {
    s = "100%"
  }

  return <div className={`${styles.spinner} ${size}`} style={{ width: s, height: s }}></div>
}

export default Spinner
