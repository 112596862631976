import React from "react"
import Markdown from "./markdown/Markdown"
import * as styles from "./AttentionBulletin.module.scss"
import { AttentionBulletData } from "./AttentionBulletins"

export type AttentionBulletinProps = AttentionBulletData

const AttentionBulletin = (props: AttentionBulletinProps) => {
  // console.debug("Rendering template AttentionBulletin", props)

  const { paragraphNode, title, validFrom, validThrough } = props
  const htmlAst = paragraphNode?.childMarkdownRemark?.htmlAst
  if (!htmlAst || !title || !validFrom) {
    console.warn("Invalid props for AttentionBulletin")
    return null
  }
  // render component if current timestamp is between validFrom and validThrough
  // or if validThrough is not provided and validFrom is before current timestamp
  const hasStarted = Date.now() > Date.parse(validFrom)
  const hasEnded = Date.now() > Date.parse(validThrough)
  if (hasEnded || !hasStarted) {
    return null
  }

  return (
    <section className={`${styles.container} full-bleed`}>
      <div>
        <div className="narrow-content">
          <h2>{title}</h2>
          <Markdown headingShift={3} htmlAst={htmlAst} />
        </div>
      </div>
    </section>
  )
}

export default AttentionBulletin
