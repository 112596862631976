import { RouteComponentProps } from "@reach/router"
import { navigate } from "gatsby"
import React, { useEffect, useRef } from "react"
import useUserState from "../../../../../hooks/useUserState"
import { objectToUrlParams } from "../../../../../utils"
import ApartmentSearchForm from "../../../../organisms/forms/ApartmentSearchForm"
import { useAparmentSearchParams } from "../../../../../hooks/useApartmentSearchParams"
import { AppPageProps } from "../../../AppPage"
import HeroImage from "../../../../organisms/HeroImage"
import DefaultLayout from "../../../../layouts/DefaultLayout"

type ApSHomeProps = RouteComponentProps & Omit<AppPageProps, "basePath" | "application">

const ApSHomePage = (props: ApSHomeProps) => {
  // console.debug("Rendering ApartmentSearchHome", props)
  const isMounted = useRef(false)
  const userState = useUserState()
  const [activeParams] = useAparmentSearchParams(userState.apartmentSearch.params, props.location?.search)

  useEffect(() => {
    if (!isMounted.current) {
      return
    }

    // console.debug("ApartmentSearchHomePage onSearchUpdate", userState.apartmentSearch, activeParams)
    const { /*resultMode, */ changeCount } = userState.apartmentSearch
    // tee lomakkeen arvoista URL-parametri lista navigaatiota varten. Parameterit välitetään myös URLissä jotta siitä tulee
    // jaettava eikä hakukriteerit ole vain piilossa
    const queryParams = objectToUrlParams(activeParams as Record<string, string>)

    if (changeCount === 0) {
      // console.debug(
      //   "Search params in userState at reset state, skiping navigate() to result page",
      //   userState.apartmentSearch
      // )
      return
    }

    // siirrä käyttäjä tulossivulle
    // navigate(`tulos?resultMode=${resultMode}&${queryParams}`)
    navigate(`tulos?${queryParams}`)

    // console.debug(formData, queryParams, resultDisplayType)
  }, [activeParams, userState.apartmentSearch])

  useEffect(() => {
    isMounted.current = true
  }, [])

  // const onSearchUpdate = (searchState: ApartmentSearchState): void => {}

  return (
    <DefaultLayout seoMetaTags={props.seoMetaTags} mainNav={props.mainNav}>
      <HeroImage image={props.image} />
      <h1>{props.title}</h1>
      {props.ingress && <p className="ingress">{props.ingress}</p>}
      <section>
        <ApartmentSearchForm params={activeParams} />
      </section>
    </DefaultLayout>
  )
}

export default ApSHomePage
